<template>
  <div class="users">
    <div class="padding_top"></div>
    <b-container>
      <b-row class="page_header" align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">Пользователи</h1>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" md="8" v-if="users">
          <b-row align-h="center">
            <p class="users__user_score count_users">
              Количество пользователей
              <span class="accent">{{ users.total }}</span>
            </p>
          </b-row>

          <b-row
            class="users__user_line bk_card"
            v-for="(user, index) in users.data"
            :key="index"
            @click="to(user.login)"
          >
            <b-col cols="2" md="auto">
              <div class="users__user_img_wrapper">
                <img
                  v-if="user.img"
                  class="users__user_img"
                  :src="$store.state.storageUrl + '/app/avatars/' + user.img"
                />
                <img
                  v-else
                  class="users__user_img"
                  :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
                />
              </div>
            </b-col>

            <b-col cols="10">
              <div class="users__user_info">
                <p class="users__user_name">
                  {{ user.name }} {{ user.last_name }}
                </p>
                <p class="users__user_description" v-html="user.about_user"></p>
                <p class="users__user_score">
                  Баллы:
                  <span class="accent">{{ user.score ? user.score : 0 }}</span>
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="users">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Users",
  data() {
    return {
      users: null,
      page: 1,
      total: 100,
      perPage: 10,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      async set(value) {
        await this.getAllUsers(value);
      },
    },
  },
  methods: {
    to(login) {
      this.$router.push({ name: "User", query: { user: login } });
    },
    async getAllUsers(page) {
      try {
        this.$store.commit("SET_LOADING", true);

        let users = await axios.get(`all_users?page=${page}`).then((res) => {
          return res.data;
        });

        this.users = users;
        this.total = this.users.total;
        this.perPage = this.users.per_page;

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
  async mounted() {
    await this.getAllUsers(this.page);
  },
};
</script>

<style lang="scss">
.users__user_line {
  margin-bottom: 15px;
  padding: 15px;
  cursor: pointer;
}

.users__user_img_wrapper {
  position: relative;
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid $gray;
}
.users__user_img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.users__user_info {
  padding-left: 15px;
}
.users__user_name {
  font-size: 18px;
  font-weight: 600;
  color: $white;
  margin: 0;
}
.users__user_description {
  font-size: 16px;
  font-weight: 400;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score {
  font-size: 14px;
  font-weight: 700;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score.count_users {
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
}
</style>
